import React, { useState,useRef,useEffect } from "react";
import { useParams, useLocation, useHistory } from 'react-router-dom';

import PropTypes from 'prop-types'
import Popup from "reactjs-popup"

import instance,{GET,POST,PUT} from "../../utils/axiosInstance"
import './Header.css'
import connections from "./connections"

import Button from "../../components/Button"
import TextInput from "../../components/TextInput"
import {Logo,Profile,Search} from "../../components/img"


const Header = ({libraryMap,keywordsMap,libraryFiltersMap,getResultList}) => {
    const [library,setLibrary]=libraryMap
    const [libraryFilters,setLibraryFilters]=libraryFiltersMap
    const [libraryList,setLibraryList]=useState([])
    const handleLibraryOnChange=(newValue)=>{
        setLibrary(newValue)
        setOpen(true)
        instance.post(
            '/library/search',
            {
                name:library,
            },
        )
        .then((res) => {
            // status error
            if (res.status !== 200) {
              console.log(res)
            }else{
                // setLibraryList([{
                //     id:0,
                //     name:'Flagged Cases',
                // },...res.data.result])
                setLibraryList(res.data.result)
            }
        })
        .catch(error=>console.log('error:',error))
    }
    const [open, setOpen] = useState(false);
    const [keywords,setKeywords]=keywordsMap
    const handleKeyDown = event =>{
        if (event.key === 'Enter') {
            getResultList()
        }
    }

    const handleFlaggedOnClick = () =>{
        setLibrary('Flagged Cases')
        instance.get('/libraryFilter/getByLibraryId',{
            params:{
                id:0
            },
            headers:{Authorization: `Bearer ${localStorage.getItem("token")}`}
          })
          .then((res) => {
              // status error
              if (res.status !== 200) {
                // onStatusError()
                setLibraryFilters([])
              }else{
                // console.log(res.data)
                setLibraryFilters(res.data)
                // getResultList()
              }
          })
          .catch(error=>console.log(error))
    }
    return(
        <div className={'Search_header'}>
            <div className={'Search_header_logo'}>
                <Logo></Logo>
            </div>
            <div className={'Search_header_library'}>
                <div className={'TextInput_container'}>
                    <div className={'TextInput_leftIcon'}><Search /></div>
                    <div className={'TextInput_right'}>
                        <input className={'TextInput_input'} 
                            type={'text'} 
                            placeholder={''}
                            value={library} 
                            onChange={e=>handleLibraryOnChange(e.target.value)} 
                            onKeyDown={e=>handleKeyDown(e)}
                        ></input>
                    </div>
                </div>
                <LibrarySelector 
                    valueSet={[library,setLibrary]}
                    list={libraryList}
                    triggerSet={[open, setOpen]}
                    setLibraryFilters={setLibraryFilters}
                    getResultList={getResultList}
                />
            </div>
            <div className={'Search_header_keywords'}>
                <TextInput icon={<Search />} placeHolder={'add keyword search'} value={keywords} handleValueOnChange={setKeywords} handleEnterPressed={getResultList}></TextInput>
            </div>
            <div className={'Search_header_flag'} onClick={handleFlaggedOnClick}>
                {'Flagged Cases'}
            </div>
            <div className={'Search_header_profile'}>
                <Profile />
            </div>
        </div>
    )
}

Header.propTypes = {
    getResultList:PropTypes.func.isRequired,
  
}

export default Header;

const LibrarySelector = ({valueSet,list,triggerSet,setLibraryFilters,getResultList}) => {
    const [library,setLibrary]=valueSet
    const [open, setOpen]=triggerSet
    const handleItemOnClick=(item)=>{
        setLibrary(item.name)
        setOpen(false)
        instance.get('/libraryFilter/getByLibraryId',{
            params:{
                id:item.id
            },
            headers:{Authorization: `Bearer ${localStorage.getItem("token")}`}
          })
          .then((res) => {
              // status error
              if (res.status !== 200) {
                // onStatusError()
                setLibraryFilters([])
              }else{
                // console.log(res.data)
                setLibraryFilters(res.data)
                // getResultList()
              }
          })
          .catch(error=>console.log(error))
    }
    return(open&&
        <div className={'HeaderLibrarySelector_container'} >
            {list.map((item,index)=>(
                // <div className={'HeaderLibrarySelector_item'} onClick={()=>handleItemOnClick(item)}>{item.name}</div>
                <div 
                    // className={'LibrarySelector_item'} 
                    className={item.name=='Flagged Cases'?'HeaderLibrarySelector_item_FlaggedCases':'HeaderLibrarySelector_item'} 
                    onClick={()=>handleItemOnClick(item)}
                >{item.name}</div>
            ))}
        </div>
    )
}

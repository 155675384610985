import React, { useState,useRef,useEffect } from "react";

import PropTypes from 'prop-types'
import './index.css'

const BlackLine = () => {
    return(
        <div className={'BlackLine_container'} >
        </div>
    )
}

BlackLine.propTypes = {
}

const GreyLine = () => {
    return(
        <div className={'GreyLine_container'} >
        </div>
    )
}

GreyLine.propTypes = {
}

export {BlackLine,GreyLine} ;
import React, { useState,useRef,useEffect } from "react";

import PropTypes from 'prop-types'
import './css/RecordContent.css'


const RecordContent = ({content}) =>{
    let points=content.split('#')
    return(
      <div className={'RecordContent_container'}>
        {points.map((point,index)=><div className={'RecordContent_point'} key={index+'container'}>
          <div className={'RecordContent_point_left'} key={index+'dot'}>{'•'}</div>
          <div className={'RecordContent_point_right'} key={index+'content'}>{point}</div>
        </div>)}
      </div>
    )
}
RecordContent.propTypes = {
    content:PropTypes.string.isRequired,
}
  
export default RecordContent;
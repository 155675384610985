import React, { useState,useRef,useEffect } from "react";
import { useParams, useLocation, useHistory } from 'react-router-dom';

import Popup from "reactjs-popup"
import PropTypes from 'prop-types'
// import instance,{GET,POST,PUT} from "../../utils/axiosInstance"
import './index.css'

import { ReactComponent as ProfileSVG } from '../../assert/Icons/Profile.svg'
import { ReactComponent as SearchSVG } from '../../assert/Icons/Search.svg'
import { ReactComponent as EmailSVG } from '../../assert/Icons/Email.svg'
import { ReactComponent as PasswordSVG } from '../../assert/Icons/Password.svg'
import ProfilePopup from '../../pages/Profile/ProfilePopup'
import Yardstick_PrimaryLogo_Reverse from '../../assert/logo/Yardstick_PrimaryLogo_Reverse.png'
import { ExpansionPanelSummary } from "@material-ui/core";


const Logo  = () => {
    return(
        <img className={'Logo_container'} src={Yardstick_PrimaryLogo_Reverse} />
    )
}
Logo.propTypes = {
}
const Profile  = () => {
    return(
        <Popup nested
            arrow={false}
            position="right top"
            trigger={<ProfileSVG className={'ProfileSVG_container'}/>}
        >
            {close => <ProfilePopup close={close}/>}
        </Popup>
        
    )
}
Profile.propTypes = {
    // handleOnClick:PropTypes.func.isRequired,
}
const Search  = () => {
    return(
        <SearchSVG className={'Search_SVG_container'}/>
    )
}
Search.propTypes = {
}

const Email  = () => {
    return(
        <EmailSVG className={'Email_SVG_container'}/>
    )
}
Email.propTypes = {
}

const Password  = () => {
    return(
        <PasswordSVG className={'Password_SVG_container'}/>
    )
}
Password.propTypes = {
}
export {Logo,Profile,Search,Email,Password};


import React, { useState,useRef,useEffect } from "react";

import PropTypes from 'prop-types'
import './Body.css'
import connections from "./connections"

import {Expander} from "../../components/Button"
import DropDownList from "../../components/DropDownList"
import {DropDownSelect,DateRangeSelect,RadioSelect,DateLengthSelect,MultiChoiceSelect} from "../../components/Filter"
import {BlackLine,GreyLine} from "../../components/Line"
import TextInput from "../../components/TextInput"
import {Logo} from "../../components/img"
import {ClientRecord} from '../../components/record'

import {
  basic_filter_options,
  Boolean_options,
  JurisdictionofSentence_options,
  AgeBracket_options,
  Typeofpenaltyimposed_options,
  ResultsPerPage,
} from "../../constants"

import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

const Body = (props) => {

    const [basic_filter,setBasic_filter]=props.basic_filterMap
    
    const [Date_start,setDate_start]=props.Date_startMap
    const [Date_end,setDate_end]=props.Date_endMap
    const [JurisdictionofSentence,setJurisdictionofSentence]=props.JurisdictionofSentenceMap
    const [PriorCriminalHistory,setPriorCriminalHistory]=props.PriorCriminalHistoryMap
    const [Partytooffenceonly,setPartytooffenceonly]=props.PartytooffenceonlyMap
    const [Attempttocommitoffenceonly,setAttempttocommitoffenceonly]=props.AttempttocommitoffenceonlyMap
    const [Sentencedalongsideothersubstantialcharges,setSentencedalongsideothersubstantialcharges]=props.SentencedalongsideothersubstantialchargesMap
    const [AgeBracket,setAgeBracket]=props.AgeBracketMap
    const [Convictionrecorded,setConvictionrecorded]=props.ConvictionrecordedMap
    const [Typeofpenaltyimposed,setTypeofpenaltyimposed]=props.TypeofpenaltyimposedMap
    const [SVOdeclarationmade,setSVOdeclarationmade]=props.SVOdeclarationmadeMap
    const [HeadSentenceLength_min,setHeadSentenceLength_min]=props.HeadSentenceLength_minMap
    const [HeadSentenceLength_max,setHeadSentenceLength_max]=props.HeadSentenceLength_maxMap
    const [ActualJailLength_min,setActualJailLength_min]=props.ActualJailLength_minMap
    const [ActualJailLength_max,setActualJailLength_max]=props.ActualJailLength_maxMap

    const [specfic_filter,setSpecfic_filter]=props.specfic_filterMap
    const [libraryFilters,setLibraryFilters]=props.libraryFiltersMap
    const [SpecialFilter1,setSpecialFilter1]=props.SpecialFilter1Map
    const [SpecialFilter2,setSpecialFilter2]=props.SpecialFilter2Map
    const [SpecialFilter3,setSpecialFilter3]=props.SpecialFilter3Map
    const [SpecialFilter4,setSpecialFilter4]=props.SpecialFilter4Map
    const [SpecialFilter5,setSpecialFilter5]=props.SpecialFilter5Map
    const [SpecialFilter6,setSpecialFilter6]=props.SpecialFilter6Map
    const [SpecialFilter7,setSpecialFilter7]=props.SpecialFilter7Map
    const [SpecialFilter8,setSpecialFilter8]=props.SpecialFilter8Map
    const [SpecialFilter9,setSpecialFilter9]=props.SpecialFilter9Map
    const [SpecialFilter10,setSpecialFilter10]=props.SpecialFilter10Map
    const [SpecialFilter11,setSpecialFilter11]=props.SpecialFilter11Map
    const [SpecialFilter12,setSpecialFilter12]=props.SpecialFilter12Map
    const SpecialFilterList = [
      [SpecialFilter1,setSpecialFilter1],
      [SpecialFilter2,setSpecialFilter2],
      [SpecialFilter3,setSpecialFilter3],
      [SpecialFilter4,setSpecialFilter4],
      [SpecialFilter5,setSpecialFilter5],
      [SpecialFilter6,setSpecialFilter6],
      [SpecialFilter7,setSpecialFilter7],
      [SpecialFilter8,setSpecialFilter8],
      [SpecialFilter9,setSpecialFilter9],
      [SpecialFilter10,setSpecialFilter10],
      [SpecialFilter11,setSpecialFilter11],
      [SpecialFilter12,setSpecialFilter12],
    ]

    const [offset,setOffset]=props.offsetMap
    const handleOffsetButtonOnChange = (direction) => {
      let newOffset
      if(direction){
        newOffset=offset+limit
      }else{
        newOffset=offset-limit
        newOffset = newOffset >= 0 ? newOffset : 0
      } 
      setOffset(newOffset)
    }

    const [limit,setLimit]=props.limitMap
    const handleLimitDropDownOnChange = (newLimit) => {
      setLimit(newLimit)
      setOffset(0)
    }

    const [records,setRecords]=props.recordsMap

    const [expand,setExpand] = useState(true)
    const [TotalResults,setTotalResults] = props.TotalResultsList

    return(
        <div className={'Search_body'}>
          <div className={'Body_container'}>
            <div className={'Search_left'}>
              {/* ----------------------------------------------------------------------------------filter */}
              <div className={'Search_left_item'}>
                <div className={'Search_TitleContent'}>{'Basic Filters'}</div>
              </div>
              <BlackLine />
              <div className={'Search_filter'}>
                {'Match '}
                <div className={'Search_filter_dropDownBox'}>
                  <DropDownList value={basic_filter} handleValueOnChange={setBasic_filter} list={basic_filter_options}/>
                </div>
                {' of the following'}
              </div>
              <GreyLine />
              {/* ----------------------------------------------------------------------------------filter */}
              <div className={'Search_left_item'}>
                <DateRangeSelect name={'Date range'} DateStartMap={[Date_start,setDate_start]} DateEndMap={[Date_end,setDate_end]} />
              </div>
              <div className={'Search_left_item'}><GreyLine /></div>
              <div className={'Search_left_item'}>
                <MultiChoiceSelect name={'Jurisdiction of Sentence'} valueMap={[JurisdictionofSentence,setJurisdictionofSentence]} list={JurisdictionofSentence_options} />
                {/* <DropDownSelect name={'Jurisdiction of Sentence'} valueMap={[JurisdictionofSentence,setJurisdictionofSentence]} list={JurisdictionofSentence_options} /> */}
              </div>
              <div className={'Search_left_item'}><GreyLine /></div>
              <div className={'Search_left_item'}>
                <RadioSelect name={'Prior criminal history'} valueMap={[PriorCriminalHistory,setPriorCriminalHistory]} ist={Boolean_options} />
              </div>
              <div className={'Search_left_item'}><GreyLine /></div>
              <div className={'Search_left_item'}>
                <RadioSelect name={'Party to offence only'} valueMap={[Partytooffenceonly,setPartytooffenceonly]} list={Boolean_options} />
              </div>
              <div className={'Search_left_item'}><GreyLine /></div>
              <div className={'Search_left_item'}>
                <RadioSelect name={'Attempt to commit offence only'} valueMap={[Attempttocommitoffenceonly,setAttempttocommitoffenceonly]} list={Boolean_options} />
              </div>
              <div className={'Search_left_item'}><GreyLine /></div>
              <div className={'Search_left_item'}>
                <RadioSelect name={'Sentenced alongside other substantial charges'} valueMap={[Sentencedalongsideothersubstantialcharges,setSentencedalongsideothersubstantialcharges]} list={Boolean_options} />
              </div>
              <div className={'Search_left_item'}><GreyLine /></div>
              <div className={'Search_left_item'}>
                <MultiChoiceSelect name={'Age bracket'} valueMap={[AgeBracket,setAgeBracket]} list={AgeBracket_options} />
              {/* <DropDownSelect name={'Age bracket'} valueMap={[AgeBracket,setAgeBracket]} list={AgeBracket_options} /> */}
              </div>
              <div className={'Search_left_item'}><GreyLine /></div>
              <div className={'Search_left_item'}>
                <RadioSelect name={'Conviction recorded'} valueMap={[Convictionrecorded,setConvictionrecorded]} list={Boolean_options} />
              </div>
              <div className={'Search_left_item'}><GreyLine /></div>
              <div className={'Search_left_item'}>
                <MultiChoiceSelect name={'Type of penalty imposed'} valueMap={[Typeofpenaltyimposed,setTypeofpenaltyimposed]} list={Typeofpenaltyimposed_options} />
                {/* <DropDownSelect name={'Type of penalty imposed'} valueMap={[Typeofpenaltyimposed,setTypeofpenaltyimposed]} list={Typeofpenaltyimposed_options} /> */}
              </div>
              <div className={'Search_left_item'}><GreyLine /></div>
              <div className={'Search_left_item'}>
                <RadioSelect name={'Was a Serious Violent Offence (SVO) declaration made'} valueMap={[SVOdeclarationmade,setSVOdeclarationmade]} list={Boolean_options} />
              </div>
              <div className={'Search_left_item'}><GreyLine /></div>
              <div className={'Search_left_item'}>
                <DateLengthSelect 
                  name={'Head sentence length'} 
                  MinMap={[HeadSentenceLength_min,setHeadSentenceLength_min]} 
                  MaxMap={[HeadSentenceLength_max,setHeadSentenceLength_max]} 
                />
              </div>
              <div className={'Search_left_item'}><GreyLine /></div>
              <div className={'Search_left_item'}>
                <DateLengthSelect 
                  name={'Actual jail length'} 
                  MinMap={[ActualJailLength_min,setActualJailLength_min]} 
                  MaxMap={[ActualJailLength_max,setActualJailLength_max]} 
                />
              </div>
              <div className={'Search_left_item'}><GreyLine /></div>
            </div>
            <div className={'Search_mid'}>
              <div className={'Search_mid_title'}>
                <div className={'Search_mid_TitleContent'}>{'Case results'}</div>
                {/* <div className={'Search_mid_ActionContent'}>{'Sentence'}</div> */}
                <div className={'Search_mid_ButtonContent'}>
                  <Expander status={expand} handleOnClick={()=>setExpand(!expand)} />
                </div>
              </div>
              <BlackLine />
              <div className={'Search_mid_BodyContent'}>
                {records.map((record,index)=>(
                  <div className={'Search_mid_item'}>
                    <ClientRecord 
                      record={record} expand={expand}
                    />
                    <GreyLine />
                  </div>
                ))}
              </div>
              <div className={'Body_mid_footer'}>
                <div className={'Body_mid_footer_left1'}>{'Results per page'}</div>
                <div className={'Body_mid_footer_left2'}>
                  <DropDownList value={limit} handleValueOnChange={handleLimitDropDownOnChange} list={ResultsPerPage} />
                </div>
                <div className={'Body_mid_footer_left3'}>{TotalResults+' Total Results'}</div>
                <div className={'Body_mid_footer_left4'}>{(offset*1+1)+'-'+(offset*1+limit*1)}</div>
                <div className={'Body_mid_footer_left5'} onClick={()=>handleOffsetButtonOnChange(false)}>{'<'}</div>
                <div className={'Body_mid_footer_left6'} onClick={()=>handleOffsetButtonOnChange(true)}>{'>'}</div>
              </div>
            </div>
            <div className={'Search_right'}>
                <div className={'Search_right_item'}>
                  <div className={'Search_TitleContent'}>{'Charge Specific Filters'}</div>
                </div>
                <BlackLine />
                <div className={'Search_filter'}>
                  {'Match '}&nbsp;
                  <div className={'Search_filter_dropDownBox'}>
                    <DropDownList value={specfic_filter} handleValueOnChange={setSpecfic_filter} list={basic_filter_options} />
                  </div>
                  &nbsp;{' of the following'}
                </div>
                <GreyLine />
                {libraryFilters.map((libraryFilter,index)=>(
                  <div className={'Search_right_itemCombo'}>
                    <div className={'Search_left_item'}>
                      {
                        {
                          'radio':<RadioSelect name={libraryFilter.filter_name}  list={Boolean_options} valueMap={SpecialFilterList[index]} />,
                          'singleChoice':<DropDownSelect name={libraryFilter.filter_name}  list={libraryFilter.filter_options.split('#')} valueMap={SpecialFilterList[index]} />,
                          'multiChoice':<MultiChoiceSelect name={libraryFilter.filter_name}  list={libraryFilter.filter_options.split('#')} valueMap={SpecialFilterList[index]} />,
                        }[libraryFilter.filter_type]
                      }
                    </div>
                    <div className={'Search_left_item'}><GreyLine /></div>
                  </div>
                ))}
            </div>
          </div>
        </div>
    )
}

Body.propTypes = {
}

export default Body;

export {
    basic_filter_options,
    Boolean_options,
    JurisdictionofSentence_options,
    AgeBracket_options,
    Typeofpenaltyimposed_options,
    ResultsPerPage,
};


const basic_filter_options = [
    // 'any',
    'all',
]

const Boolean_options = [
    'Yes',
    'No',
]

const JurisdictionofSentence_options = [
    'Magistrates Court',
    'District Court (first instance)',
    'District Court (appeal)',
    'Supreme Court (first instance)',
    'Court of Appeal',
]

const AgeBracket_options = [
    '18-19',
    '20-25',
    '26-29',
    '30+',
    'unknown',
    'juvenile sentenced as an adult',
]

const Typeofpenaltyimposed_options = [
    'Absolute discharge, CNFP, bond or fine',
    'Probation / CSO',
    'ICO',
    'Imprisonment with immediate release',
    'Imprisonment order involving actual custody',
]

const CircumstanceofAggravation_options = [
    'Absolute discharge, CNFP, bond or fine',
    'Probation / CSO',
    'ICO',
    'Imprisonment with immediate release',
    'Imprisonment order involving actual custody',
]

const ResultsPerPage = [
    1,5,10,15,20,
]
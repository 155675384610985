
import {useHistory} from "react-router-dom"
import React, { useState,useRef,useEffect } from "react";

import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types'
import instance,{GET,POST,PUT} from "../../utils/axiosInstance"
import './index.css'
import ProfileDetailPopup from "./ProfileDetailPopup"



const ProfilePopup = ({close}) => {
    const history=useHistory()
    const [modify,setModify] = useState(false)
    const onClose = () => {
        setModify(false)
        close()
    }

    const handleSignoutOnClick=()=>{
        history.push({ pathname:'/',})
        localStorage.removeItem('u_id')
    }
    
    return(
        <div className={'ProfilePopup_container'}>
            <div className={'ProfilePopup_content'} onClick={()=>setModify(true)} >{'Profile'}</div>
            <div className={'ProfilePopup_content'} onClick={handleSignoutOnClick} >{'Sign out'}</div>
            <ProfileDetailPopup modify={modify} onClose={onClose} />
        </div>
    )
}


export default ProfilePopup;


import {useHistory} from "react-router-dom"
import React, { useState,useRef,useEffect } from "react";

import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types'
import TextInput from "../../components/TextInput"
import {Logo,Email,Password,} from "../../components/img"
import instance,{GET,POST,PUT} from "../../utils/axiosInstance"
import './index.css'



const ProfileDetailPopup = ({modify,onClose}) => {
  const history=useHistory()

  const [user,setUser] =useState(null)
  const [password,setPassword] =useState('')

  useEffect(() => {
    instance.get('/user',{
      params:{
        id:localStorage.getItem('u_id')
      },
    })
    .then((res) => {
        // status error
        if (res.status !== 200) {
          alert('session expired, need relogin')
        }else{
          setUser(res.data)
        }
    })
    .catch((error)=>console.log(error))
  }, [])

  const handleEditOnClick = () =>{
    instance.put('/user',{
      id:localStorage.getItem('u_id'),
      password
    })
    .then((res) => {
        // status error
        if (res.status !== 200) {
          alert('session expired, need relogin')
        }else{
          alert('password change succeed')
          onClose()
        }
    })
    .catch((error)=>console.log(error))
  }

    return(
      <Dialog className={'popup_container'}
        open={modify} onClose={onClose}
      >
        <div className={'Profile_container'}>
          <div className={'Profile_title'}>{'Profile'}</div>
          <div className={'Profile_input'}>
            <TextInput icon={<Email />} value={user?user.email:''}></TextInput>
          </div>
          <div className={'Profile_TextInput_container'}>
              <div className={'Profile_TextInput_leftIcon'}><Password /></div>
              <div className={'Profile_TextInput_mid'}>
                <input className={'Profile_TextInput_input'} 
                  type={'password'} 
                  value={password} 
                  onChange={e=>setPassword(e.target.value)} 
                ></input>
              </div>
              <div className={'Profile_TextInput_rightIcon'} onClick={handleEditOnClick}>{'Edit'}</div>
          </div>
          <div className={'Profile_gap'}></div>
        </div>
      </Dialog>
    )
}

ProfileDetailPopup.propTypes = {
}

export default ProfileDetailPopup;


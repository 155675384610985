import axios from "axios";
import React, {useContext} from "react";

const instance = axios.create({
  // baseURL: "http://localhost:3001",
  // baseURL: "http://3.104.65.234",
  baseURL: "https://www.yardstick.legal",
  timeout: 5000,
});

export default instance;

const GET=(url,params,statusCode,dataCode,onThen,onStatusError,onDataError)=>{
    axios.get(url,{
      params,
      headers:{Authorization: `Bearer ${localStorage.getItem("token")}`}
    })
    .then((res) => {
        // status error
        if (res.status !== statusCode) {
          onStatusError()
        }else if(res.data.code != dataCode){
          onDataError()
        }else{
          onThen(res.data)
        }
    })
    .catch((error)=>onStatusError(error))
}
const POST=(url,body,statusCode,dataCode,onThen,onStatusError,onDataError)=>{
  axios.post(
    url,
    body,
    {headers:{Authorization: `Bearer ${localStorage.getItem("token")}`}}
  )
  .then((res) => {
      // status error
      if (res.status !== statusCode) {
        onStatusError()
      }else if(res.data.code != dataCode){
        onDataError()
      }else{
        onThen(res.data)
      }
  })
  .catch((error)=>onStatusError(error))
}
const PUT=(url,body,statusCode,dataCode,onThen,onStatusError,onDataError)=>{
  axios.put(
    url,
    body,
    {headers:{Authorization: `Bearer ${localStorage.getItem("token")}`}}
  )
  .then((res) => {
      // status error
      if (res.status !== statusCode) {
        onStatusError()
      }else if(res.data.code != dataCode){
        onDataError()
      }else{
        onThen(res.data)
      }
  })
  .catch((error)=>onStatusError(error))
}

export {GET,POST,PUT}
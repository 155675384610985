import React, { useState,useRef,useEffect } from "react";

import { makeStyles } from '@material-ui/core/styles'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import PropTypes from 'prop-types'
import './index.css'

import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

const Index = ({placeholder,value,handleValueOnChange}) => {
    const classes = useStyles();
    const [years,setyears]=useState([])
    useEffect(() => {
        let newyears=[]
        for (let year = moment().year(); year >= 1900; year--) {
            newyears.push(year)
        }
        setyears(newyears)
    }, [])
    return(
        <select 
            className={classes.datePicker} 
            placeholderText={placeholder}
            onChange={e=>handleValueOnChange(e.target.value)} 
            // size={10}
            // onChange={selected=>handleValueOnChange(moment(selected).format("DD/MM/YYYY"))} 
        >
            {years.map((year,index)=>(
                <option value={'01/01/'+year}>{year}</option>
            ))}
        </select>
    )
}

Index.propTypes = {
  placeHolder:PropTypes.string,
  value:PropTypes.string.isRequired,
  handleValueOnChange:PropTypes.func.isRequired,
}
const useStyles = makeStyles((theme) => ({
    datePicker:{
        width:'100%',
        height:28,
        backgroundColor:'#F2F2F2',
        border:0,
        outline:'none',
        borderRadius:10,

        // position:'ab'

        fontSize:10,
        fontWeight:500,
        textAlign:'center',
        cursor:'pointer',
    }
}))
export default Index;
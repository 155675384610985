import React, { useState,useRef,useEffect } from "react";
import { useParams, useLocation, useHistory } from 'react-router-dom';

import PropTypes from 'prop-types'
import './index.css'
import connections from "./connections"

import {Expander} from "../../components/Button"
import TextInput from "../../components/TextInput"
import {Logo} from "../../components/img"
import instance,{GET,POST,PUT} from "../../utils/axiosInstance"

import Header from "./Header"
import Body from "./Body"

import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);


const Index = (props) => {
    const location=useLocation()
    // console.log(location.state)
  // parames for sending req
    const [library,setLibrary] =useState('')
    const [keywords,setKeywords] =useState('')
    const [offset,setOffset]=useState(0)
    const [limit,setLimit]=useState(10)
    const [TotalResults,setTotalResults]=useState(0)

    const [basic_filter,setBasic_filter]=useState('all')
    const [Date_start,setDate_start]=useState('')
    const [Date_end,setDate_end]=useState('')
    const [JurisdictionofSentence,setJurisdictionofSentence]=useState('')
    const [PriorCriminalHistory,setPriorCriminalHistory]=useState('')
    const [Partytooffenceonly,setPartytooffenceonly]=useState('')
    const [Attempttocommitoffenceonly,setAttempttocommitoffenceonly]=useState('')
    const [Sentencedalongsideothersubstantialcharges,setSentencedalongsideothersubstantialcharges]=useState('')
    const [AgeBracket,setAgeBracket]=useState('')
    const [Convictionrecorded,setConvictionrecorded]=useState('')
    const [Typeofpenaltyimposed,setTypeofpenaltyimposed]=useState('')
    const [SVOdeclarationmade,setSVOdeclarationmade]=useState('')
    const [HeadSentenceLength_min,setHeadSentenceLength_min]=useState(0)
    const [HeadSentenceLength_max,setHeadSentenceLength_max]=useState(0)
    const [ActualJailLength_min,setActualJailLength_min]=useState(0)
    const [ActualJailLength_max,setActualJailLength_max]=useState(0)

    const [specfic_filter,setSpecfic_filter]=useState('all')
    const [SpecialFilter1,setSpecialFilter1]=useState('')
    const [SpecialFilter2,setSpecialFilter2]=useState('')
    const [SpecialFilter3,setSpecialFilter3]=useState('')
    const [SpecialFilter4,setSpecialFilter4]=useState('')
    const [SpecialFilter5,setSpecialFilter5]=useState('')
    const [SpecialFilter6,setSpecialFilter6]=useState('')
    const [SpecialFilter7,setSpecialFilter7]=useState('')
    const [SpecialFilter8,setSpecialFilter8]=useState('')
    const [SpecialFilter9,setSpecialFilter9]=useState('')
    const [SpecialFilter10,setSpecialFilter10]=useState('')
    const [SpecialFilter11,setSpecialFilter11]=useState('')
    const [SpecialFilter12,setSpecialFilter12]=useState('')
    
    // params for control
    const [libraryFilters,setLibraryFilters]=useState([])
    
    const [records,setRecords]=useState([])
      useEffect(() => {
        if(location.state && location.state.library) setLibrary(location.state.library)
        if(location.state && location.state.libraryFilters) setLibraryFilters(location.state.libraryFilters)
      }, [])
      useEffect(() => {
        getResultList()
      }, [
        offset,limit,
        basic_filter,Date_start,Date_end,JurisdictionofSentence,PriorCriminalHistory,Partytooffenceonly,Attempttocommitoffenceonly,
        Sentencedalongsideothersubstantialcharges,AgeBracket,Convictionrecorded,Typeofpenaltyimposed,SVOdeclarationmade,
        HeadSentenceLength_min,HeadSentenceLength_max,ActualJailLength_min,ActualJailLength_max,
        specfic_filter,SpecialFilter1,SpecialFilter2,SpecialFilter3,SpecialFilter4,SpecialFilter5,SpecialFilter6,SpecialFilter7,SpecialFilter8,
        SpecialFilter9,SpecialFilter10,SpecialFilter11,SpecialFilter12,
      ])
  // params for result
    const getResultList = () => {
      let thisLib = library||location.state.library
      // alert('getResultList')
      instance.post(
        '/record/search',
        {
          offset,limit,library:thisLib,
          u_id:localStorage.getItem('u_id'),
          record:{
            keywords,
            // basic filter
            basic_filter,
            Date_start:dateFormat(Date_start),
            Date_end:dateFormat(Date_end),
            JurisdictionofSentence,PriorCriminalHistory,
            Partytooffenceonly,Attempttocommitoffenceonly,Sentencedalongsideothersubstantialcharges,
            AgeBracket,Convictionrecorded,Typeofpenaltyimposed,SVOdeclarationmade,HeadSentenceLength_min,
            HeadSentenceLength_max,ActualJailLength_min,ActualJailLength_max,
            // specfic filter
            specfic_filter,SpecialFilter1,SpecialFilter2,SpecialFilter3,SpecialFilter4,SpecialFilter5,
            SpecialFilter6,SpecialFilter7,SpecialFilter8,SpecialFilter9,SpecialFilter10,SpecialFilter11,SpecialFilter12,
          }
        },
      )
      .then((res) => {
            // status error
            if (res.status !== 200) {
              alert('server connect error')
            }else{
              // console.log(res.data)
              // history.push({ pathname:'/librarySelect',})
              setTotalResults(res.data.amount)
              setRecords(res.data.result)
            }
      })
      .catch(error=>console.log('invalid library, please reselect your library!'))
    }
    
    useEffect(() => {
      setKeywords('')
      setDate_start('')
      setDate_end('')
      setJurisdictionofSentence('')
      setPriorCriminalHistory('')
      setPartytooffenceonly('')
      setAttempttocommitoffenceonly('')
      setSentencedalongsideothersubstantialcharges('')
      setAgeBracket('')
      setConvictionrecorded('')
      setTypeofpenaltyimposed('')
      setSVOdeclarationmade('')
      setHeadSentenceLength_min(0)
      setHeadSentenceLength_max(0)
      setActualJailLength_min(0)
      setActualJailLength_max(0)
      setSpecialFilter1('')
      setSpecialFilter2('')
      setSpecialFilter3('')
      setSpecialFilter4('')
      setSpecialFilter5('')
      setSpecialFilter6('')
      setSpecialFilter7('')
      setSpecialFilter8('')
      setSpecialFilter9('')
      setSpecialFilter10('')
      setSpecialFilter11('')
      setSpecialFilter12('')
      getResultList()

    }, [library])

    return(
      <div className={'Search_container'}>
          <Header 
            libraryMap={[library,setLibrary]}
            keywordsMap={[keywords,setKeywords]}
            libraryFiltersMap={[libraryFilters,setLibraryFilters]}
            getResultList={getResultList}
          />
          <Body 
            basic_filterMap={[basic_filter,setBasic_filter]}
            Date_startMap={[Date_start,setDate_start]}
            Date_endMap={[Date_end,setDate_end]}
            JurisdictionofSentenceMap={[JurisdictionofSentence,setJurisdictionofSentence]}
            PriorCriminalHistoryMap={[PriorCriminalHistory,setPriorCriminalHistory]}
            PartytooffenceonlyMap={[Partytooffenceonly,setPartytooffenceonly]}
            AttempttocommitoffenceonlyMap={[Attempttocommitoffenceonly,setAttempttocommitoffenceonly]}
            SentencedalongsideothersubstantialchargesMap={[Sentencedalongsideothersubstantialcharges,setSentencedalongsideothersubstantialcharges]}
            AgeBracketMap={[AgeBracket,setAgeBracket]}
            ConvictionrecordedMap={[Convictionrecorded,setConvictionrecorded]}
            TypeofpenaltyimposedMap={[Typeofpenaltyimposed,setTypeofpenaltyimposed]}
            SVOdeclarationmadeMap={[SVOdeclarationmade,setSVOdeclarationmade]}
            HeadSentenceLength_minMap={[HeadSentenceLength_min,setHeadSentenceLength_min]}
            HeadSentenceLength_maxMap={[HeadSentenceLength_max,setHeadSentenceLength_max]}
            ActualJailLength_minMap={[ActualJailLength_min,setActualJailLength_min]}
            ActualJailLength_maxMap={[ActualJailLength_max,setActualJailLength_max]}

            specfic_filterMap={[specfic_filter,setSpecfic_filter]}
            libraryFiltersMap={[libraryFilters,setLibraryFilters]}
            SpecialFilter1Map={[SpecialFilter1,setSpecialFilter1]}
            SpecialFilter2Map={[SpecialFilter2,setSpecialFilter2]}
            SpecialFilter3Map={[SpecialFilter3,setSpecialFilter3]}
            SpecialFilter4Map={[SpecialFilter4,setSpecialFilter4]}
            SpecialFilter5Map={[SpecialFilter5,setSpecialFilter5]}
            SpecialFilter6Map={[SpecialFilter6,setSpecialFilter6]}
            SpecialFilter7Map={[SpecialFilter7,setSpecialFilter7]}
            SpecialFilter8Map={[SpecialFilter8,setSpecialFilter8]}
            SpecialFilter9Map={[SpecialFilter9,setSpecialFilter9]}
            SpecialFilter10Map={[SpecialFilter10,setSpecialFilter10]}
            SpecialFilter11Map={[SpecialFilter11,setSpecialFilter11]}
            SpecialFilter12Map={[SpecialFilter12,setSpecialFilter12]}

            offsetMap={[offset,setOffset]}
            limitMap={[limit,setLimit]}
            recordsMap={[records,setRecords]}
            getResultList={getResultList}
            TotalResultsList={[TotalResults,setTotalResults]}

          />
      </div>
    )
}

Index.propTypes = {
}

export default Index;

// format front-end date format (DD/MM/YYYY) to mysql date format (YYYY-MM-DD)
const dateFormat = (frontDate) =>{
  if(frontDate){
    let frontendDate = moment(frontDate,'DD/MM/YYYY')
    let mysqlDate = frontendDate.format('YYYY-MM-DD')
    return mysqlDate
  }else{
    return frontDate
  }
}
import {useHistory} from "react-router-dom"
import React, { useState,useRef,useEffect } from "react";
import {isMobile} from 'react-device-detect'

import PropTypes from 'prop-types'
import './index.css'
import connections from "./connections"

import {ClickOnButton} from "../../components/Button"
import ForgetPassword from "../ForgetPassword"
import TextInput from "../../components/TextInput"
import {Logo,Email,Password,} from "../../components/img"
import YardstickMobilePrompt from '../../assert/imgs/Yardstick Mobile Prompt@4x.png'


const Index = (props) => {
  const history=useHistory()

  const [email,setEmail] =useState('')
  const [password,setPassword] =useState('')
  const [open,setOpen] = useState(false)

  // if(isMobile){
  if(isMobile){
    return(<div className={'Login_container'}>
      <img className={'MobileView_image'} src={YardstickMobilePrompt} />
    </div>)
  }
    return(
      <div className={'Login_container'}>
          <div className={'Login_left'} >
            <div className={'Login_left_container'}>
              <div className={'Login_left_container_text'}>{'To start searching on Yardstick, log in using the below details.'}</div>
              <div className={'Login_left_container_input'}>
                <TextInput icon={<Email />} placeHolder={'email'} value={email} handleValueOnChange={setEmail} ></TextInput>
              </div>
              <div className={'Login_left_container_input'}>
                <TextInput icon={<Password />} placeHolder={'password'} type={'password'} value={password} handleValueOnChange={setPassword} ></TextInput>
              </div>
              <div className={'Login_left_container_button'}>
                <ClickOnButton text={'Log In'} onClick={()=>connections.handleLoginInOnClick(email,password,history)} />
              </div>
            </div>
            <div className={'Login_left_buttom'} onClick={()=>setOpen(true)}>
              {'Forgot Password?'}
            </div>
            <ForgetPassword open={open} onClose={()=>setOpen(false)} userEmail={email} />
          </div>
          <div className={'Login_right'}>
            <div className={'Login_right_logo'}><Logo /></div>
          </div>
      </div>
    )
}

Index.propTypes = {
}

export default Index;


import React, { useState,useRef,useEffect } from "react";

import PropTypes from 'prop-types'
import './index.css'

const Index = ({value,handleValueOnChange,list}) => {
    return(
      <select className={'DropDownList_select'} value={value} onChange={e=>handleValueOnChange(e.target.value)}>
        {list.map((item,index)=>
          <option className={'DropDownList_option'} value ={item}>{item}</option>
        )}
      </select>
    )
}

Index.propTypes = {
  value:PropTypes.string.isRequired,
  setValue:PropTypes.func.isRequired,
  list:PropTypes.array.isRequired,
}

export default Index;
import React, { useState } from "react";
import {
    Route,
    Switch,
    HashRouter,
} from "react-router-dom";

import Proptypes from 'prop-types'

import Login from "../Login"
import Search from "../Search"
import LibrarySelect from "../LibrarySelect"


const Router = (props) =>{
    return(
      <HashRouter>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route path="/search">
            <Search />
          </Route>
          <Route path="/librarySelect">
            <LibrarySelect />
          </Route>
          {/* <Route path="/test">
            <Main />
          </Route> */}
        </Switch>
      </HashRouter>
    )
}
Router.propTypes = {
}
export default Router;
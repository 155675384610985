import instance,{GET,POST,PUT} from "../../utils/axiosInstance"

const handleForgotPasswordOnClick=(email,setState)=>{
    instance.get('/user/forgetPassword',{
      params:{
        email
      },
    })
    .then((res) => {
        // status error
        if (res.status !== 200) {
          setState(false)
        }else{
          setState(true)
        }
    })
    .catch((error)=>console.log(error))
}

const handleLoginInOnClick=(email,password,history)=>{
    instance.post(
        '/user/login',
        {email,password},
    )
    .then((res) => {
          // status error
          if (res.status !== 200) {
            alert('email or password incorrect!')
          }else{
            let {user}=res.data
            history.push({ pathname:'/librarySelect',})
            localStorage.setItem('u_id',user.id)
            // console.log(res.data)
          }
    })
    .catch(error=>alert('invalid email or password!'))
}


const connections = {
    handleForgotPasswordOnClick,
    handleLoginInOnClick,
}
export default connections

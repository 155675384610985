import {useHistory} from "react-router-dom"
import React, { useState,useRef,useEffect } from "react";

import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types'
import {ClickOnButton} from "../../components/Button"
import TextInput from "../../components/TextInput"
import {Logo,Email,Password,} from "../../components/img"
import instance,{GET,POST,PUT} from "../../utils/axiosInstance"
import './index.css'

const Index = ({open,onClose,userEmail}) => {
  const [email,setEmail] =useState(userEmail)
  const [ResetState,setResetState] = useState(false)
  const handleForgetPasswordButtonOnClick = () =>{
    instance.get('/user/forgetPassword',{
      params:{
        email
      },
    })
    .then((res) => {
        // status error
        if (res.status !== 200) {
          setResetState(false)
        }else{
          setResetState(true)
        }
    })
    .catch((error)=>console.log(error))
  }
    return(
      <Dialog className={'popup_container'}
        open={open} onClose={onClose}
      >
        <div className={'ForgetPassword_container'}>
          <div className={'ForgetPassword_title'}>{'Reset Password'}</div>
          <div className={'ForgetPassword_content'}>{'Enter your email to send a password reset link.'}</div>
          <div className={'ForgetPassword_input'}>
            <TextInput icon={<Email />} placeHolder={'email'} value={email} handleValueOnChange={setEmail} ></TextInput>
          </div>
          {ResetState?
            <div className={'ForgetPassword_success'}>{'Email has sent successfully'}</div>
            :
            <div className={'ForgetPassword_button'}>
              <ClickOnButton text={'Send Reset Link'} onClick={handleForgetPasswordButtonOnClick} />
            </div>
          }
          <div className={'ForgetPassword_gap'}></div>
        </div>
      </Dialog>
    )
}

Index.propTypes = {
}

export default Index;


import React, { useState,useRef,useEffect } from "react";

import PropTypes from 'prop-types'
import './index.css'

import { ReactComponent as UpArrowSVG } from '../../assert/Icons/Up Arrow.svg'
import downArrowPNG from '../../assert/Icons/down Arrow.png'


const ClickOnButton = ({text,onClick}) => {
    return(
        <div className={'ClickOnButton_container'} onClick={onClick}>
            {text}
        </div>
    )
}

ClickOnButton.propTypes = {
    text:PropTypes.string.isRequired,
    onClick:PropTypes.func.isRequired,
}

const Expander = ({status,handleOnClick}) => {
    return(
        <div className={'Expander_container'} onClick={handleOnClick}>
            {status ? <UpArrowSVG className={'Expander_UpArrow'}/> : <img src={downArrowPNG} className={'Expander_DownArrow'}/>}
        </div>
    )
}

Expander.propTypes = {
    status:PropTypes.bool.isRequired,
    handleOnClick:PropTypes.func.isRequired,
}

const Flagged = ({status,handleOnClick}) => {
    return(
        <div className={status?'Flagged_container_flagged':'Flagged_container_unflagged'} onClick={handleOnClick}>
            +
        </div>
    )
}

Flagged.propTypes = {
    status:PropTypes.bool.isRequired,
    handleOnClick:PropTypes.func.isRequired,
}

export {ClickOnButton,Expander,Flagged};
import {useHistory} from "react-router-dom"
import React, { useState,useRef,useEffect } from "react";

import PropTypes from 'prop-types'
import './index.css'
// import connections from "./connections"

import instance,{GET,POST,PUT} from "../../utils/axiosInstance"
import Button from "../../components/Button"
import TextInput from "../../components/TextInput"
import {Logo,Search,} from "../../components/img"


const Index = (props) => {
  const history=useHistory()

  const [open, setOpen] = useState(false);
  const [library,setLibrary]=useState('')
  const [libraryFilters,setLibraryFilters]=useState([])
  const [libraryList,setLibraryList]=useState([])
  const handleLibraryOnChange=(newValue)=>{
      setLibrary(newValue)
      setOpen(true)
      instance.post(
          '/library/search',
          {
            name:newValue,
          },
      )
      .then((res) => {
          // status error
          if (res.status !== 200) {
            console.log(res)
          }else{
              // console.log(res.data)
            setLibraryList([{
                id:0,
                name:'Flagged Cases',
            },...res.data.result])
          }
      })
      .catch(error=>console.log('error:',error))
  }
    return(
      <div className={'LibrarySelect_container'}>
        <div className={'LibrarySelect_content'}>
            <div className={'LibrarySelect_logo'}><Logo /></div>
            <div className={'LibrarySelect_input'}>
                <div className={'LibrarySelect_inputArea'}>
                    <TextInput icon={<Search />} placeHolder={'Search for an offence'} value={library} handleValueOnChange={handleLibraryOnChange} ></TextInput>
                </div>
                <LibrarySelector 
                    valueSet={[library,setLibrary]}
                    list={libraryList}
                    triggerSet={[open, setOpen]}
                    setLibraryFiltersSet={[libraryFilters,setLibraryFilters]}
                />
            </div>
        </div>
      </div>
    )
}

Index.propTypes = {
}

export default Index;

const LibrarySelector = ({valueSet,list,triggerSet,setLibraryFiltersSet}) => {
    const history=useHistory()
    const [library,setLibrary]=valueSet
    const [libraryFilters,setLibraryFilters]=setLibraryFiltersSet
    const [open, setOpen]=triggerSet
    let LibState={}
    const handleItemOnClick=(item)=>{
        setLibrary(item.name)
        setOpen(false)
        instance.get('/libraryFilter/getByLibraryId',{
            params:{
                id:item.id
            },
            headers:{Authorization: `Bearer ${localStorage.getItem("token")}`}
        })
        .then((res) => {
              // status error
              if (res.status !== 200) {
                // onStatusError()
              }else{
                setLibraryFilters(res.data)
                history.push({
                    pathname:'/search',
                    state:{
                        library:item.name,
                        libraryFilters:res.data
                    }
                })

              }
        })
        .catch(error=>console.log(error))
        // history.push({
        //     pathname:'/search',
        //     state:{
        //         library:item.name,
        //         libraryFilters:null
        //     }
        // })
    }
    return(open&&
        <div className={'LibrarySelector_container'} >
            {list.map((item,index)=>(
                <div 
                    // className={'LibrarySelector_item'} 
                    className={item.name=='Flagged Cases'?'LibrarySelector_item_FlaggedCases':'LibrarySelector_item'} 
                    onClick={()=>handleItemOnClick(item)}
                >{item.name}</div>
            ))}
        </div>
    )
}
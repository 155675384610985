import React, { useState,useRef,useEffect } from "react";

import PropTypes from 'prop-types'
import './index.css'

const Index = ({icon,type,placeHolder,value,handleValueOnChange,handleEnterPressed}) => {
    const handleKeyDown = event =>{
      if (event.key === 'Enter') {
          handleEnterPressed()
      }
    }
    
    return(
      <div className={'TextInput_container'}>
        <div className={'TextInput_leftIcon'} onClick={handleEnterPressed}>
          {icon}
        </div>
        <div className={'TextInput_right'}>
          <input className={'TextInput_input'} 
            type={type || 'text'} 
            placeholder={placeHolder || ''}
            value={value} 
            onChange={e=>handleValueOnChange(e.target.value)} 
            onKeyDown={handleEnterPressed?e=>handleKeyDown(e) : null}
          ></input>
        </div>
      </div>
    )
}

Index.propTypes = {
  icon:PropTypes.node.isRequired,
  type:PropTypes.string,
  placeHolder:PropTypes.string,
  value:PropTypes.string.isRequired,
  handleValueOnChange:PropTypes.func.isRequired,
  handleEnterPressed:PropTypes.func.isRequired,
}

export default Index;
import React, { useState,useRef,useEffect } from "react";

import PropTypes from 'prop-types'
import DropDownList from "../DropDownList"
import DatePicker from "./DatePicker"
import './index.css'

import { ReactComponent as Check } from '../../assert/Icons/Check.svg'
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

const DropDownSelect = ({name,valueMap,list}) => {
    const [value,setValue]=valueMap
    const [selected,setSelected]=useState(false)

    const handleIconOnClick = () =>{
      setValue('')
      setSelected(!selected)
    }
    useEffect(() => {
      if(value==''){
        setSelected(false)
      }
    }, [value])
    return(
      <div className={'FilterSelect_container'}>
        <div className={'FilterSelect_showed'}>
          <div className={'FilterSelect_icon'} onClick={handleIconOnClick}>
            {selected?
              <Check />
            :
              <div className={'FilterSelect_iconBlank'} />
            }
          </div>
          <div className={'FilterSelect_name'}>
            {name}
          </div>
        </div>
        {selected && 
          <div className={'FilterSelect_hidden'}>
            <DropDownList value={value} handleValueOnChange={setValue} list={list} />
          </div>
        }
      </div>
    )
}
DropDownSelect.propTypes = {
  name:PropTypes.string.isRequired,
  valueMap:PropTypes.array.isRequired,
  list:PropTypes.array.isRequired,
}

const DateRangeSelect = ({name,DateStartMap,DateEndMap}) => {
  const [Date_start,setDate_start]=DateStartMap
  const [Date_end,setDate_end]=DateEndMap
  const [selected,setSelected]=useState(false)

  const handleIconOnClick = () =>{
    setSelected(!selected)
    setDate_start('')
    setDate_end('')
  }
  useEffect(() => {
    if(Date_start==''&&Date_end==''){
      setSelected(false)
    }
  }, [Date_start,Date_end])
  return(
    <div className={'FilterSelect_container'}>
      <div className={'FilterSelect_showed'}>
          <div className={'FilterSelect_icon'} onClick={handleIconOnClick}>
            {selected?
              <Check />
            :
              <div className={'FilterSelect_iconBlank'} />
            }
          </div>
          <div className={'FilterSelect_name'}>
            {name}
          </div>
      </div>
      {selected && 
        <div className={'FilterSelect_hidden'}>
          <div className={'DateRangeSelect_datePickerArea'}>
            <DatePicker placeholder={'Start Date'} value={Date_start} handleValueOnChange={setDate_start} />
          </div>
          <div className={'DateRangeSelect_words'}>to</div>
          <div className={'DateRangeSelect_datePickerArea'}>
            <DatePicker placeholder={'End Date'} value={Date_end} handleValueOnChange={setDate_end} />
          </div>
        </div>
      }
    </div>
  )
}

DateRangeSelect.propTypes = {
  name:PropTypes.string.isRequired,
  DateStartMap:PropTypes.array,
  DateEndMap:PropTypes.array,
}



const RadioSelect = ({name,valueMap}) => {
  const [value,setValue]=valueMap
  const [selected,setSelected]=useState(false)

  const handleIconOnClick = () =>{
    setValue('')
    setSelected(!selected)
    // if(!selected){
    //   setValue('')
    // }
  }
  useEffect(() => {
    if(value==''){
      setSelected(false)
    }
  }, [value])
  return(
    <div className={'FilterSelect_container'}>
      <div className={'FilterSelect_showed'}>
        <div className={'FilterSelect_icon'} onClick={handleIconOnClick}>
          {selected?
            <Check />
          :
            <div className={'FilterSelect_iconBlank'} />
          }
        </div>
        <div className={'FilterSelect_name'}>
          {name}
        </div>
      </div>
      {selected && 
        <div className={'FilterSelect_hidden'}>
          <div 
            className={value=='Yes'?'RadioSelect_Button_Selected':'RadioSelect_Button_unSelected'}
            onClick={()=>setValue('Yes')}
          >{'Yes'}</div>
          <div className={'FilterSelect_Gap'}></div>
          <div
            className={value=='No'?'RadioSelect_Button_Selected':'RadioSelect_Button_unSelected'}
            onClick={()=>setValue('No')}
          >{'No'}</div>
        </div>
      }
    </div>
  )
}
RadioSelect.propTypes = {
  name:PropTypes.string.isRequired,
  valueMap:PropTypes.array.isRequired,
}

const DateLengthSelect = ({name,MinMap,MaxMap}) => {
  const [Min,setMin]= MinMap
  const [Max,setMax]= MaxMap

  const [MinYear,setMinYear]=useState(0)
  const [MinMonth,setMinMonth]=useState(0)
  const [MaxYear,setMaxYear]=useState(0)
  const [MaxMonth,setMaxMonth]=useState(0)

  const [selected,setSelected]=useState(false)
  const [Minselected,setMinSelected]=useState(true)
  const [Maxselected,setMaxSelected]=useState(true)

  const handleMinYearOnChange = newMinYear =>{
    setMinYear(newMinYear)
    let number = (newMinYear*12)+MinMonth*1
    setMin(number)
  }

  const handleMinMonthOnChange = newMinMonth =>{
    setMinMonth(newMinMonth)
    let number = (MinYear*12)+newMinMonth*1
    setMin(number)
  }

  const handleMaxYearOnChange = newMaxYear =>{
    setMaxYear(newMaxYear)
    let number = (newMaxYear*12)+MaxMonth*1
    setMax(number)

  }

  const handleMaxMonthOnChange = newMaxMonth =>{
    setMaxMonth(newMaxMonth)
    let number = +(MaxYear*12)+newMaxMonth*1
    setMax(number)

  }

  const handleIconOnClick = () =>{
    if(selected){
      setMin(0)
      setMinSelected(true)
      setMax(0)
      setMaxSelected(true)

    }else{
      setMinYear(Min/12)
      setMinMonth(Min%12)
      setMaxYear(Max/12)
      setMaxMonth(Max%12)
    }
    setSelected(!selected)
  }

  const handleCancelOnClick=(lineNumber)=>{
    if(lineNumber==1){
      setMin(0)
      setMinSelected(false)
      if(!Maxselected) handleIconOnClick()

    }
    if(lineNumber==2){
      setMax(0)
      setMaxSelected(false)
      if(!Minselected) handleIconOnClick()
    }
  }
  // const [testDate,setTestDate]=useState(moment().format("DD/MM/YYYY"))
  useEffect(() => {
    if(MinMap==0&&MaxMap==0){
      setSelected(false)
    }
  }, [MinMap,MaxMap])
  return(
    <div className={'FilterSelect_container'}>
      <div className={'FilterSelect_showed'}>
          <div className={'FilterSelect_icon'} onClick={handleIconOnClick}>
            {selected?
              <Check />
            :
              <div className={'FilterSelect_iconBlank'} />
            }
          </div>
          <div className={'FilterSelect_name'}>
            {name}
          </div>
      </div>
      {selected && 
        <div className={'DateLengthSelect_hidden'}>
          {Minselected &&
            <div className={'DateLengthSelect_hiddenLines'}>
              <div className={'DateLengthSelect_hiddenLines_Title'}>{'MIN'}</div>
              <div className={'DateLengthSelect_hiddenLines_Button'}>
                <DateInput value={MinYear} setValue={handleMinYearOnChange} text={'yrs'}/>
              </div>
              <div className={'DateLengthSelect_hiddenLines_Button'}>
                <DateInput value={MinMonth} setValue={handleMinMonthOnChange} text={'mths'}/>
              </div>
              <div className={'DateLengthSelect_hiddenLines_Cancel'} onClick={()=>handleCancelOnClick(1)}>X</div>
            </div>
          }
          {Maxselected &&
            <div className={'DateLengthSelect_hiddenLines'}>
              <div className={'DateLengthSelect_hiddenLines_Title'}>{'MAX'}</div>
              <div className={'DateLengthSelect_hiddenLines_Button'}>
                <DateInput value={MaxYear} setValue={handleMaxYearOnChange} text={'yrs'}/>
              </div>
              <div className={'DateLengthSelect_hiddenLines_Button'}>
                <DateInput value={MaxMonth} setValue={handleMaxMonthOnChange} text={'mths'}/>
              </div>
              <div className={'DateLengthSelect_hiddenLines_Cancel'} onClick={()=>handleCancelOnClick(2)}>X</div>
            </div>
          }
        </div>
      }
    </div>
  )
}

DateLengthSelect.propTypes = {
  name:PropTypes.string.isRequired,
  DateStartMap:PropTypes.array,
  DateEndMap:PropTypes.array,
}

const MultiChoiceSelect = ({name,valueMap,list}) => {
  const [value,setValue]=valueMap
  const [selected,setSelected]=useState(false)

  const handleIconOnClick = () =>{
    setSelected(!selected)
    if(!selected){
      setValue('')
    }
  }
  useEffect(() => {
    if(value==''){
      setSelected(false)
    }
  }, [value])
  const handleItemOnClick = item =>{
    let valueList=[]
    list.forEach(option => {
      if (option==item && value.indexOf(option)==-1) {
        valueList.push(option)
      }else if(option!=item && value.indexOf(option)!=-1){   //deal with other records that not join in this event
        valueList.push(option)
      } 
    })
    setValue(valueList.join('#'))
  }
  return(
    <div className={'FilterSelect_container'}>
      <div className={'FilterSelect_showed'}>
        <div className={'FilterSelect_icon'} onClick={handleIconOnClick}>
          {selected?
            <Check />
          :
            <div className={'FilterSelect_iconBlank'} />
          }
        </div>
        <div className={'FilterSelect_name'}>
          {name}
        </div>
      </div>
      {selected && 
        <div className={'MultiChoiceSelect_hidden'}>
          {list.map((item,index)=>(
            <div className={'MultiChoiceSelect_showed'}>
              <div className={'FilterSelect_icon'} onClick={()=>handleItemOnClick(item)}>
                {value.indexOf(item)!=-1?<Check />:<div className={'MultiChoiceSelect_iconBlank'} />}
              </div>
              <div className={'MultiChoiceSelect_name'}>{item}</div>
            </div>
          ))}
        </div>
      }
    </div>
  )
}
MultiChoiceSelect.propTypes = {
  name:PropTypes.string.isRequired,
  valueMap:PropTypes.array.isRequired,
  list:PropTypes.array.isRequired,
}

export {DropDownSelect,DateRangeSelect,RadioSelect,DateLengthSelect,MultiChoiceSelect};

const DateInput = ({value,setValue,text}) => {
  return(
    <div className={'Filter_DateInput_container'}>
      <input type={'text'} className={'Filter_DateInput_input'} value={value} onChange={e=>setValue(e.target.value)} maxLength={2} ></input>
      <div className={'Filter_DateInput_text'}>{text}</div>
    </div>
  )
}
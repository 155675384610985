import React, { useState,useRef,useEffect } from "react";
import renderHTML from 'react-render-html'

import PropTypes from 'prop-types'
import './css/index.css'
import './css/ClientRecord.css'

import instance,{GET,POST,PUT} from "../../utils/axiosInstance"
import {Logo,Profile,Search} from "../../components/img"
import {Expander,Flagged} from "../../components/Button"
import RecordContent from "./RecordContent"

// for client record 
const ClientRecord = ({record,expand}) => {
  const [selected,setSelected]=useState(false)
  useEffect(() => {
    setSelected(expand)
  }, [expand])
  const [flagTrigger,setFlagTrigger]=useState(false)
  const [flagged,setFlagged]=useState(false)
  const isFlagged = () =>{
    instance.get('/record/flagRecord',{
      params:{
        u_id:localStorage.getItem('u_id'),
        r_id:record.id,
      },
    })
    .then((res) => {
        // status error
        if (res.status !== 200) {
          // onStatusError()
        }else{
          setFlagged(res.data)
        }
    })
    .catch(error=>console.log(error))
  }
  useEffect(() => {
    isFlagged()
  }, [])
  const handleFlagButtonOnClick = () =>{
    let url='/record/flagRecord'
    let u_id=localStorage.getItem('u_id')
    let r_id=record.id
    if(flagged){
      instance.delete(
        url,
        {data:{u_id,r_id}},
      )
      .then((res) => {
          // status error
          if (res.status !== 200) {
            // onStatusError()
          }else{
            setFlagged(false)
          }
      })
      .catch(error=>console.log(error))
    }else{
      instance.post(
        url,
        {u_id,r_id},
      )
      .then((res) => {
          // status error
          if (res.status !== 200) {
            // onStatusError()
          }else{
            setFlagged(true)
          }
      })
      .catch(error=>console.log(error))
    }
  }

  let SentenceLength=parseInt(record.HeadSentenceLength/12)+' yrs '+record.HeadSentenceLength%12+' mths'

    return(
      <div className={'Record_container'}>
        <div className={'Record_showed'}>
          <div className={'Record_left'}>
            <div className={'ClientRecord_left_title'}
              onMouseEnter={()=>setFlagTrigger(true)}
              onMouseLeave={()=>setFlagTrigger(false)}
            >
              <div className={flagTrigger?'ClientRecord_left_titleContent_selected':'ClientRecord_left_titleContent_unselected'} 
              >
                {renderHTML(record.title)}
              </div>
              {flagTrigger&&
                <div className={'ClientRecord_left_titleflagTrigger'}>
                  <Flagged status={flagged} handleOnClick={handleFlagButtonOnClick}/>
                </div>
              }
            </div>
            <div className={'ClientRecord_left_description'}>{record.description}</div>
            {/* placeholder for external link below */}
            <div className={'ClientRecord_left_description'}>
              <a href={record.link} target="_Blank">Link to case</a>
            </div>
            <div className={'Record_left_gap'}></div>
          </div>
          <div className={'Record_right'}>
            <div className={'Record_right_content'}>
              <Expander status={selected} handleOnClick={()=>setSelected(!selected)} />
            </div>
          </div>
        </div>
        {selected && <div className={'ClientRecord_hidden'}>
          <RecordContent content={record.content}/>
        </div>}
        <div className={'Record_buttom_gap'}></div>
      </div>
    )
}

ClientRecord.propTypes = {
  record:PropTypes.object.isRequired,
}

export default ClientRecord;
